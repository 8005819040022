import React, { useEffect, useState } from 'react';
import { useVirtualDevicesParamsSelector } from 'hooks/device-management';
import { useForm } from 'hooks/form';
import { DeviceType } from 'models/device-emulation';
import { MessageType, SendMessageField, SendMessageState } from './widgets/SendMessageForm';
import { findDeviceType, getShowFieldsByState, useNextOccupancyStatus, useSendMessageAction } from './utils';

// components
import { CommonButton } from 'components/Buttons';
import { CommonDialog } from 'components/Dialogs';
import { SendMessageForm } from './widgets/SendMessageForm';

// styles


interface Props {
  deviceId: string;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const SendMessageDialog = (props: Props) => {
  const { deviceId: defaultDeviceId, isOpen, onClose, onCloseEnd } = props;
  const { devices } = useVirtualDevicesParamsSelector({ deviceIds: [defaultDeviceId] });
  const [formState, setFormState] = useState<SendMessageState>(() => {
    const defaultDeviceType = findDeviceType(defaultDeviceId, devices);
    const defaultMessageType = defaultDeviceType === DeviceType.CAR_COUNTER
      ? MessageType.REGULAR_CAR_COUNTER
      : MessageType.OCCUPANCY_CHANGE;
    return {
      [SendMessageField.device]: defaultDeviceId,
      [SendMessageField.messageType]: defaultMessageType,
      [SendMessageField.occupancy]: '',
      [SendMessageField.tag]: '',
      [SendMessageField.counter]: 0,
    };
  });

  const handleChange = (newState: SendMessageState, field?: SendMessageField) => {
    if (field === SendMessageField.counter) {
      const newCounter = Number(newState[SendMessageField.counter]);
      newState[SendMessageField.counter] = Math.max(0, Math.min(1023, newCounter));
    }
    setFormState(prevState => ({
      ...prevState,
      ...newState
    }));
  };

  const deviceType = findDeviceType(formState[SendMessageField.device] ?? '', devices);
  const [occupancyStatus, submitDisabled] = useNextOccupancyStatus(formState, devices);
  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      [SendMessageField.occupancy]: occupancyStatus,
    }));
  }, [occupancyStatus]);

  const form = useForm<SendMessageState, SendMessageField>({
    initialState: formState,
    state: formState,
    onChange: handleChange,
    showFields: getShowFieldsByState(formState),
  });

  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const sendMessage = useSendMessageAction(devices);
  const handleSubmit = (): void => {
    if (!form.validate()) {
      return;
    }

    setSubmitted(true);
    sendMessage(formState)
      .finally(() => setSubmitted(false));
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title="Send message"
      content={ (
        <SendMessageForm
          { ...form }
          disabledFields={ [SendMessageField.occupancy] }
          deviceType={ deviceType }
        />
      ) }
      actions={
        <>
          <CommonButton
            label="Cancel"
            type="text"
            onClick={ onClose }
          />
          <CommonButton
            label="Send"
            type="button"
            icon={ <></> }
            onClick={ handleSubmit }
            help={ submitDisabled ? `Occupancy hasn't been loaded yet` : undefined }
            isDisabled={ isSubmitted || submitDisabled }
            isLoading={ isSubmitted }
          />
        </>
      }
    />
  );
};
