import { API } from 'aws-amplify';
import { staticEnv } from 'env';

import { BindDeviceToPosition, ReplaceDevice, UnbindDeviceFromPosition } from 'actions/device-management/devices';
import { ApiResponse, ReasonEnum } from 'models';
import {
  Device,
  DeviceColdData,
  DeviceUpdateParams,
  ExistingPosition,
  FindDevicesParams,
  GetLabelsParams,
  GroupCreateFields,
  LabelCreateFields,
  LevelUpdateFields,
  Metric,
  PositionGroupUpdateParams,
  PositionsLabelsAttachBody,
  ResponseDevice,
  ResponseDeviceColdData,
  ResponseDevicesWithTotal,
  ResponseGroup,
  ResponseGroupsWithTotal,
  ResponseLabel,
  ResponseLabels,
  ResponseLevel,
  ResponseLevels,
  ResponsePositionedDevicesWithTotal,
  ResponsePositionsWithTotal,
  ResponseStatistic,
  ResponseZone,
  ResponseZonesWithTotal,
  Zone,
  ZoneUpdateFields,
  ZoneUpdateParams
} from 'models/device-management';
import {
  BoundStatus,
  EmulatedStatus,
  GetDmStatParams,
  GroupsFilterParams,
  LevelsFilters,
  PositionedStatus,
  PositionsFilterFields,
  ZoneFiltersParams,
} from 'models/device-management/filters';
import { DamagedStatuses } from 'models/common';
import { getOptionalDateInstance } from 'utils/datetime';
import { Defined } from 'utils/models';

import { errorHandler, formatQuery } from 'clients/utils';

const urls = {
  zoneById: (zoneId: Defined<Zone['id']>) => `/zone/${zoneId}`,
  zones: '/zones',
  deviceById: (deviceId: string) => `/device/${deviceId}`,
  fetchDeviceColdData: (deviceId: Device['device_id']) => `/device/${deviceId}/coldData`,
  fetchPosition: (positionId: ExistingPosition['id']) => `/position/${positionId}`,
  findZones: '/zones/find',
  findHardwareTypes: '/devices/hardwareTypes',
  findGroups: '/groups/find',
  findPositions: '/positions/find',
  findDevices: '/devices/find',
  findPositionedDevices: '/devices/positioned/find',
  groupById: (groupId: number) => `/group/${groupId}`,
  createGroup: '/groups',
  statAll: '/statistics/all',
  replaceDevices: '/devices/replaceDevices',
  bindDeviceToPosition: (deviceId: Device['device_id']) => `/device/${deviceId}/bindToPosition`,
  unbindDeviceFromPosition: (deviceId: Device['device_id']) => `/device/${deviceId}/unbindFromPosition`,
  level: (levelId: number) => `/level/${levelId}`,
  levels: '/levels',
  labels: '/labels',
  labelById: (labelId: number) => `/labels/${labelId}`,
  attachLabelsToPositions: '/positions/labels/attach',
  detachLabelsFromPositions: '/positions/labels/detach',
};

export const config = {
  name: 'dm',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://dm.prod.api.nwave.io' : 'https://dm.dev.api.nwave.io',
};

export const getZoneById = (zoneId: number): Promise<ResponseZone> => {
  return API
    .get(config.name, urls.zoneById(zoneId), {})
    .catch(errorHandler);
};

export const fetchZones = (params: ZoneFiltersParams): Promise<ResponseZonesWithTotal> => {
  const queryStringParameters = formatQuery({
    owner_id: params.owner,
    zone_id: params.zones,
    project_id: params.projects,
    live_status: params.liveStatus,
    limit: params.limit,
    offset: params.offset,
  });

  const init = {
    queryStringParameters,
  };

  return API
    .get(config.name, urls.findZones, init)
    .catch(errorHandler);
};

export const updateZone = (params: ZoneUpdateParams, pureError?: boolean): Promise<ApiResponse> => {
  const init = {
    body: params.props,
  };

  return API
    .put(config.name, urls.zoneById(params.id), init)
    .catch(pureError ? e => { throw new Error(e); } : errorHandler);
};

export const createZone = (params: ZoneUpdateFields, pureError?: boolean): Promise<ResponseZone> => { // ??
  const parameters = {
    body: {
      name: params.name,
      live_status: params.live_status,
      project_id: params.project_id
    }
  };

  return API
    .post(config.name, urls.zones, parameters)
    .catch(pureError ? e => { throw new Error(e); } : errorHandler);
};

export const deleteZone = (zoneId: number, pureError?: boolean): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.zoneById(zoneId), {})
    .catch(pureError ? e => { throw new Error(e); } : errorHandler);
};

export const fetchHardwareTypes = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.findHardwareTypes, {})
    .catch(errorHandler);
};

export type TFetchDevicesParams = {
  limit?: number;
  offset?: number;
  positions?: number[];
  levels?: number[];
  labelIds?: number[];
  owner?: number;
  groupIds?: number[];
  groupInnerIds?: string[];
  projects?: number[];
  zones?: number[];
  bindingStatus?: string;
  networkIds?: string[];
  customIds?: string[];
  lat?: number;
  lon?: number;
  radius?: number;
  devices?: string[];
  deviceIds?: string[];
  isActive?: string | boolean;
  isVirtual?: boolean;
  hardwareTypes?: string[];
  isDamaged?: string | boolean;
  groups?: number[];
  isPositioned?: string;
  isEmulated?: EmulatedStatus[];
  firmwareId?: string | number;
  protocol?: number;
  force?: boolean;
  boxesIds?: number[];
  keysEnabled?: boolean
}

export const fetchDevices = (params: TFetchDevicesParams): Promise<ResponsePositionedDevicesWithTotal> => {
  const damageStatusKey = params.isDamaged as keyof typeof DamagedStatuses;
  const placementStatusKey = params.isPositioned as keyof typeof PositionedStatus;

  let virtual: boolean | undefined = undefined;
  if (params.isEmulated) {
    const emulatedStatus = params.isEmulated as EmulatedStatus[];
    switch (true) {
      case emulatedStatus.includes(EmulatedStatus.emulate) && emulatedStatus.includes(EmulatedStatus.real):
        virtual = undefined; // nothing filter
        break;
      case emulatedStatus.includes(EmulatedStatus.emulate):
        virtual = true;
        break;
      case emulatedStatus.includes(EmulatedStatus.real):
        virtual = false;
        break;
    }
  }

  const queryParams = formatQuery({
    limit: params.limit,
    offset: params.offset,
    force: params.force ?? true,
    device_id: params.devices || params.deviceIds,
    hardware_type: params.hardwareTypes,
    zone_id: params.zones,
    group_id: params.groups,
    owner_id: params.owner,
    project_id: params.projects,
    position_id: params.positions,
    group_inner_id: params.groupInnerIds,
    level_id: params.levels,
    firmware_hash: params.firmwareId,
    protocol: params.protocol,
    network_id: params.networkIds,
    lat: params.lat,
    keys: params.keysEnabled,
    lon: params.lon,
    radius: params.radius,
    box_id: params.boxesIds,
    active: params.isActive,
    damaged: params.isDamaged
      ? DamagedStatuses[damageStatusKey] === DamagedStatuses.damaged
      : undefined,

    positioned_status: params.isPositioned
      ? PositionedStatus[placementStatusKey] === PositionedStatus.positioned
      : undefined,

    virtual: virtual ?? params.isVirtual
  });

  const body = {
    queryStringParameters: queryParams,
  };

  return API
    .get(config.name, urls.findPositionedDevices, body)
    .catch(errorHandler);
};

export const deleteGroup = (groupId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.groupById(groupId), {})
    .catch(errorHandler);
};

export const fetchGroups = (params: GroupsFilterParams): Promise<ResponseGroupsWithTotal> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      zone_id: params.zones,
      group_id: params.groups,
      owner_id: params.owner,
      project_id: params.projects,
      group_type: params.groupType,
      level_id: params.levels,
    }),
  };

  return API
    .get(config.name, urls.findGroups, body)
    .catch(errorHandler);
};

export const updateGroup = (params: PositionGroupUpdateParams): Promise<ApiResponse> => {
  const init = {
    body: {
      ...params.props,
      level_id: params.props.level_id ?? null,
    },
  };

  return API
    .put(config.name, urls.groupById(params.id), init);
};

export const fetchAllStatistics = (params: GetDmStatParams): Promise<ResponseStatistic> => {
  const metrics: Metric[] = params.metrics ?? [
    Metric.InstalledNumber,
    Metric.ReplacementNumber,
    Metric.DamagedInstalledNumber,
    Metric.EmptyPositionsNumber,
    Metric.DamageLogByDate,
    Metric.ReplacementLogByDate,
    Metric.ByZoneIds,
    Metric.ByGroupIds
  ];

  const body = {
    queryStringParameters: formatQuery({
      metric: metrics,
      zone_id: params.zones,
      group_id: params.groups,
      date_from: params.timeFrom?.toISOString(),
      date_to: params.timeTo?.toISOString(),
      project_id: params.projects,
      owner_id: params.owner
    }),
  };

  return API
    .get(config.name, urls.statAll, body)
    .catch(errorHandler);
};

// TODO: make date conversions in reducer
export const fetchDeviceColdData = (deviceId: Device['device_id']): Promise<ResponseDeviceColdData> => {
  return API
    .get(config.name, urls.fetchDeviceColdData(deviceId), {})
    .then(res => {
      const data: DeviceColdData = res.data;
      if (res.reason !== ReasonEnum.Ok) {
        return res;
      }

      const deviceColdData: DeviceColdData = {
        ...data,
        last_fw_updatetime: getOptionalDateInstance(data.last_fw_updatetime),
        assembling_datetime: getOptionalDateInstance(data.assembling_datetime),
        purchase_datetime: getOptionalDateInstance(data.purchase_datetime),
        expiration_datetime: getOptionalDateInstance(data.expiration_datetime),
        disabling_datetime: getOptionalDateInstance(data.disabling_datetime),
        last_update_datetime: getOptionalDateInstance(data.last_update_datetime),
      };

      return { reason: res.reason, message: res.message, data: deviceColdData };
    })
    .catch(errorHandler);
};

export const findDevices = (params: FindDevicesParams): Promise<ResponseDevicesWithTotal> => {
  const reqParams = {
    queryStringParameters: formatQuery({
      limit: params.limit ?? 2000,
      offset: params.offset ?? 0,
      damaged: params.isDamaged,
      active: params.isActive,
      virtual: params.isVirtual,
      keys: params.includeKeys,
      owner_id: params.ownerId,
      device_id: params.deviceIds,
      hardware_type: params.hardwareType,
      firmware_hash: params.firmwareHash,
    }),
  };

  return API
    .get(config.name, urls.findDevices, reqParams)
    .catch(errorHandler);
};

export const deletePosition = (positionId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.fetchPosition(positionId), {})
    .catch(errorHandler);
};

export const findPositions = (params: PositionsFilterFields): Promise<ResponsePositionsWithTotal> => {
  const reqParams = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      group_id: params.groupIds,
      position_id: params.positions,
      level_id: params.levels,
      label_id: params.labelIds,
      owner_id: params.owner,
      group_inner_id: params.groupInnerIds,
      project_id: params.projects,
      zone_id: params.zones,
      network_id: params.networkIds,
      custom_id: params.customIds,
      lat: params.lat,
      lon: params.lon,
      radius: params.radius,

      bound: params.bindingStatus
        ? BoundStatus[params.bindingStatus as keyof typeof BoundStatus] === BoundStatus.bound
        : undefined,
    }),
  };

  return API
    .get(config.name, urls.findPositions, reqParams)
    .catch(errorHandler);
};

export const updatePosition = (position: ExistingPosition): Promise<ApiResponse> => {
  const reqParams = {
    body: position,
  };

  return API
    .put(config.name, urls.fetchPosition(position.id), reqParams)
    .catch(errorHandler);
};

export const replaceDevice = (params: ReplaceDevice['params']): Promise<ApiResponse> => {
  const reqParams = {
    body: params,
  };

  return API
    .put(config.name, urls.replaceDevices, reqParams)
    .catch(errorHandler);
};

export const bindDeviceToPosition = (params: BindDeviceToPosition['params']): Promise<ApiResponse> => {
  const reqParams = {
    body: { position_id: params.positionId },
  };

  return API
    .post(config.name, urls.bindDeviceToPosition(params.deviceId), reqParams)
    .catch(errorHandler);
};

export const unbindDeviceFromPosition = (params: UnbindDeviceFromPosition['params']): Promise<ApiResponse> => {
  const reqParams = {
    body: { position_id: params.positionId },
  };

  return API
    .post(config.name, urls.unbindDeviceFromPosition(params.deviceId), reqParams)
    .catch(errorHandler);
};

export const getDeviceById = (deviceId: string): Promise<ResponseDevice> => {
  return API
    .get(config.name, urls.deviceById(deviceId), {})
    .catch(errorHandler);
};

export const updateDevice = (params: DeviceUpdateParams): Promise<ApiResponse> => {
  const init = {
    body: params.props,
  };

  return API
    .put(config.name, urls.deviceById(params.id), init)
    .catch(errorHandler);
};

export const getDevice = (deviceId: Device['device_id']): Promise<ResponseDevice> => {
  return API
    .get(config.name, urls.deviceById(deviceId), {})
    .catch(errorHandler);
};

export const createGroup = (body: GroupCreateFields[]): Promise<ResponseGroup> => {
  const parameters = {
    body
  };

  return API
    .post(config.name, urls.createGroup, parameters)
    .catch(errorHandler);
};

export const fetchLevels = (params: LevelsFilters): Promise<ResponseLevels> => {
  const queryParams = formatQuery({
    limit: params.limit,
    offset: params.offset,
    zone_id: params.zones,
    project_id: params.projects,
    owner_id: params.owner,
    floor_number: params.floorNumber,
    level_id: params.levels,
  });

  const reqParams = {
    queryStringParameters: queryParams,
  };

  return API
    .get(config.name, urls.levels, reqParams)
    .catch(errorHandler);
};

export const createLevel = (level: LevelUpdateFields): Promise<ResponseLevel> => {
  const reqParams = {
    body: level,
  };

  return API
    .post(config.name, urls.levels, reqParams)
    .catch(e => {
      throw new Error(e);
    });
};

export const updateLevel = (levelId: number, level: LevelUpdateFields): Promise<ResponseLevel> => {
  const reqParams = {
    body: level,
  };

  return API
    .put(config.name, urls.level(levelId), reqParams)
    .catch(e => { throw new Error(e); });
};

export const deleteLevel = (levelId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.level(levelId), {})
    .catch(e => { throw new Error(e); });
};

export const getLabels = (params: GetLabelsParams): Promise<ResponseLabels> => {
  const reqParams = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      owner_id: params.owner,
    }),
  };

  return API
    .get(config.name, urls.labels, reqParams)
    .catch(errorHandler);
};

export const createLabel = (params: LabelCreateFields): Promise<ResponseLabel> => {
  const reqParams = {
    body: params,
  };

  return API
    .post(config.name, urls.labels, reqParams)
    .catch(errorHandler);
};

export const deleteLabel = (labelId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.labelById(labelId), {})
    .catch(errorHandler);
};

export const attachLabelsToPositions = (params: PositionsLabelsAttachBody): Promise<ApiResponse> => {
  const reqParams = {
    body: params,
  };

  return API
    .post(config.name, urls.attachLabelsToPositions, reqParams)
    .catch(errorHandler);
};

export const detachLabelsFromPositions = (params: PositionsLabelsAttachBody): Promise<ApiResponse> => {
  const reqParams = {
    body: params,
  };

  return API
    .post(config.name, urls.detachLabelsFromPositions, reqParams)
    .catch(errorHandler);
};
