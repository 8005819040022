import { Theme, alpha } from '@mui/material';
import { fonts } from 'styles/themes/outer';
import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogContainer: {
    display: 'flex',
    // alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
  dialogPaper: {
    margin: 0,
    height: '100%',
    maxHeight: '100%',
    width: '30%',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  actionsRoot: {
    marginRight: theme.spacing(2),
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    fontFamily: fonts.textSecondary,
    fontSize: '18px',
    lineHeight: 1.44,
  },
  dialogCloseBtn: {
    color: theme.palette.text.primary,
  },
  dialogContent: {
    padding: '8px 24px !important',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: alpha(theme.palette.primary.main, .38),
    display: 'flex',
    flexDirection: 'column',
  },
  dialogActions: {
    marginRight: theme.spacing(2),
  },
}));
