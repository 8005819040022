import { useState, FC } from 'react';
import moment from 'moment';
import { LoRaWANRawMessagesFilters, initLoRaWANRawMessagesFilters } from 'models';
import { StationEntity } from 'models/base-station';

// components
import { Box } from '@mui/material';
import CustomIdSelect from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { NetworkIdSelect } from 'components/Controls/NetworkIdSelect/NetworkIdSelectComponent';
import { TextControl, DateTimeControl, SwitchControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';
import { GatewayEUIControl } from './GatewayEUIControl';

// @TODO think about reuse isStationFiltered (./BaseStation/ListPage/utils/useStationsByFilters.ts)
const stationSelectFilter = (state: LoRaWANRawMessagesFilters) => (station: StationEntity) => {
  if (state.owner && state.owner !== station.owner_id)  {
    return false;
  }

  if (state.projects.length > 0 && (!station.location?.project || !state.projects.includes(station.location.project))) {
    return false;
  }

  return true;
};


type Props = {
  defaultFilters: LoRaWANRawMessagesFilters;
  autoUpdate: boolean;
  onSave: (filters: LoRaWANRawMessagesFilters) => void;
  onReset: () => void;
}

export const LorawanMessagesFilter: FC<Props> = ({ defaultFilters, autoUpdate, onSave, onReset }) => {
  const [state, setState] = useState(defaultFilters);
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const handleSelectGroups = (groups: number[]) => setState({ ...state, groups });
  const handleSelectOwner = (owner?: number) => setState({ ...state, owner });

  return (
    <Box display="flex" justifyContent="end">
      <FilterButton
        isLoading={ false}
        filter={ state }
        onSave={ () => onSave(state) }
        onReset={ () => { onReset(); setState(initLoRaWANRawMessagesFilters); } }
      >
        <form autoComplete="off">
          <GroupedFilters
            owner={ state.owner }
            handleSelectOwner={ handleSelectOwner }
            isProjectShow
            projects={ state.projects }
            handleSelectProjects={ handleSelectProjects }
            isZoneShow
            zones={ state.zones }
            handleSelectZones={ handleSelectZones }
            isGroupsShow
            groups={ state.groups }
            handleSelectGroups={ handleSelectGroups }
          />
          <CustomIdSelect
            selected={ state.devices }
            changeHandler={ devices => setState({ ...state, devices }) }
            name="Device"
            type="hex"
          />
          <NetworkIdSelect
            selected={ state.networkId }
            changeHandler={ networkId => setState({ ...state, networkId }) }
          />
          <GatewayEUIControl
            isClearable
            isMulti={ false }
            selected={ state.gatewayEUI }
            onChange={ gatewayEUI => setState({ ...state, gatewayEUI }) }
            filter={ stationSelectFilter(state) }
          />
          <TextControl
            label="Message Trace ID"
            value={ state.traceid }
            onChange={ event => setState({ ...state, traceid: event.target.value.trim() || '' }) }
            type="text"
          />
          <DateTimeControl
            label="Time From"
            helperText="Message Demodulation time"
            onChange={ date => setState({ ...state, timeFrom: date ? moment(date) : null }) }
            value={ state.timeFrom ? state.timeFrom.toDate() : undefined }
            minDate={ (state.timeTo?.clone() ?? moment()).subtract(31, 'days').startOf('day').toDate() }
            maxDate={ state.timeTo?.toDate() }
            hideErrorsFromHelperText
            isClearable
          />
          <DateTimeControl
            label="Time To"
            helperText="Message Demodulation time"
            isDisabled={ autoUpdate }
            onChange={ date => setState({ ...state, timeTo: date ? moment(date) : null }) }
            value={ state.timeTo ? state.timeTo.toDate() : undefined }
            minDate={ state.timeFrom?.toDate() }
            isClearable
          />
          <SwitchControl
            name="unique"
            label="Unique"
            value={ state.unique ?? false }
            onChange={ value => setState({ ...state, unique: value || undefined }) }
          />
        </form>
      </FilterButton>
    </Box>);
};