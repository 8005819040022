import { API } from 'aws-amplify';
import { errorHandler, formatQuery } from 'clients/utils';
import { config } from './config';
import { GetMessageParam, Message } from 'models/message-history';
import { ApiResponse } from 'models';

const urls = {
  message: '/message',
};

export const getMessage = (param: GetMessageParam): Promise<ApiResponse<Message>> => {
  const options = {
    queryStringParameters: formatQuery({
      trace_id: param.trace_id,
    })
  };

  return API
    .get(config.name, urls.message, options)
    .catch(errorHandler);
};
