import { jsonPrettySafe } from 'utils/json';
import { styled } from 'styles/utils';

// components
import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';
import { CopyToClipboardButton } from 'components/Buttons';

const Pre = styled('pre')(() => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflowX: 'scroll'
}));


interface Props {
  title: string
  isLoading?: boolean;
  valueJson?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export const JsonPreview = (props: Props): JSX.Element => {
  if (props.isLoading) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={ -1 }>
        <Skeleton width={ 100 }/>
      </Box>
    );
  }

  const value = props.valueJson ?? JSON.stringify(props.value ?? undefined);
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={ -1 }>
        <Typography variant="subtitle2">{ props.title }:</Typography>
        <CopyToClipboardButton copied="Content was copied" type="icon" value={ value }/>
      </Box>
      <Box border={ 1 } p={ 1 } borderRadius={ 3 } maxHeight={ 300 } overflow="scroll">
        <Pre> { jsonPrettySafe(value) }</Pre>
      </Box>
    </>
  );
};

