import { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useFormActionNotifier } from 'hooks';
import { clearQueryCache } from 'utils/react-query';
import { DoFetchProvisioningDevicesByFilters } from 'actions/provisioning';
import { Device, ProvisioningDevicesFiltersFields, ShipmentDeviceForAws, AwsProvisioningDevice } from 'models/provisioning';
import { deleteDeviceFromAws, provideDeviceToAws } from 'clients/provisioning';

export interface DeviceState {
  device?: Device;
  isLoading: boolean;
  reload: () => void;
}

export function useProvisioningDeviceSelector(deviceId: string, enabled = true): DeviceState {
  const dispatch = useDispatch();
  const loadData = useCallback(() => DoFetchProvisioningDevicesByFilters({
    [ProvisioningDevicesFiltersFields.device_id]: [deviceId.toUpperCase()],
    [ProvisioningDevicesFiltersFields.box_id]: [],
    [ProvisioningDevicesFiltersFields.shipment_id]: [],
    [ProvisioningDevicesFiltersFields.device_status]: undefined,
    [ProvisioningDevicesFiltersFields.owner]: [],
    [ProvisioningDevicesFiltersFields.limit]: 1,
    [ProvisioningDevicesFiltersFields.offset]: 0,
  }), [deviceId]);

  useEffect(() => {
    enabled && dispatch(loadData());
  }, [dispatch, enabled, loadData]);

  const { device, isLoading } = useSelector((state: RootState) => ({
    isLoading: state.provisioning.devices.isFetching,
    device: state.provisioning.devices.devices.find(d => d.device_id.toUpperCase() === deviceId.toUpperCase()),
  }), shallowEqual);

  return {
    device,
    isLoading,
    reload: () => { dispatch(loadData()); }
  };
}

export const useDeleteDeviceFromAws = () => {
  const { notifyError } = useFormActionNotifier();
  const [isDeleting, setIsDeleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const deleteDeviceFormAws = async (awsIds: string[]) => {
    setIsDeleting(true);
    const promises = awsIds.map(awsId => ({
      promise: deleteDeviceFromAws(awsId),
      awsId: awsId
    }));
    try {
      let completedCount = 0;
      const errors: string[] = [];
      await Promise.all(
        promises.map(({ promise, awsId }) =>
          promise.then(res => {
            completedCount++;
            setProgress((completedCount / promises.length) * 100);
            if (res.reason !== 'ok') {
              errors.push(awsId);
            }
          })
        )
      );
      if (errors.length > 0) {
        throw new Error(`Failed to delete ${errors.length} devices from AWS`);
      }
    } catch(e) {
      notifyError((e as Error).message);
    } finally {
      setIsDeleting(false);
      setProgress(0);
      await clearQueryCache([
        'provisioning/shipment/devices',
        'provisioning/shipment/received/devices',
        'provisioning/aws/devices'
      ]);
    }
  };
  return {
    deleteDeviceFormAws,
    progress,
    isDeleting,
  };
};

export type ShipmentDeviceForAwsWithAppKey = ShipmentDeviceForAws & { app_key?: string };

const createDeviceForAws = (device: ShipmentDeviceForAwsWithAppKey, profileId: string): AwsProvisioningDevice => {
  return {
    device_id: device.device_id,
    device_profile_id: profileId,
    service_profile_id: '74819283-c3fb-46b9-ae47-3d2c624ad724', // const
    otaav1_0_x: {
      app_key: device?.app_key,
      app_eui: '70b3d57ed0029283' // const
    }
  };
};

export const useDeviceProvision = () => {
  const { notifyError } = useFormActionNotifier();
  const [isProvisioning, setIsProvisioning] = useState(false);
  const [progress, setProgress] = useState(0);

  const bulkProvisionDeviceToAws = async (devices: ShipmentDeviceForAwsWithAppKey[], profileId: string) => {
    setIsProvisioning(true);
    try {
      let completedCount = 0;
      const errors: string[] = [];
      for (const d of devices) {
        try {
          const res = await provideDeviceToAws(createDeviceForAws(d, profileId));
          completedCount++;
          setProgress((completedCount / devices.length) * 100);

          if (res.reason !== 'ok') {
            errors.push(d.device_id);
          }
        } catch (error) {
          errors.push(d.device_id);
        }
      }
      if (errors.length > 0) {
        throw new Error(errors.join(','));
      }
    } catch (e) {
      notifyError(`Error while provisioning shipment aws devices: ${(e as Error).message}`);
    } finally {
      setProgress(0);
      setIsProvisioning(false);
      await clearQueryCache([
        'provisioning/shipment/devices',
        'provisioning/shipment/received/devices',
        'provisioning/aws/devices'
      ]);
    }
  };

  return {
    bulkProvisionDeviceToAws,
    progress,
    isProvisioning,
  };
};
