import { API } from 'aws-amplify';
import { ApiResponse } from 'models';
import { errorHandler, formatQuery } from '../utils';
import { LoRaWANRawMessagesQuery, LorawanMessage } from 'models';
import { config }  from './config';

const urls = {
  messages: '/raw_lorawan_messages',
  total: '/raw_lorawan_messages/total',
};

function normalizeParams(params: LoRaWANRawMessagesQuery) {
  return {
    limit: params.limit,
    offset: params.offset,
    owner_id: params.owner,
    project_id: params.projects,
    group_id: params.groups,
    zone_id: params.zones,
    device_id: params.devices,
    network_id: params.networkId,
    gateway_eui: params.gatewayEUI,
    trace_id: params.traceid || null,
    timestamp_from: params.timeFrom?.toISOString(),
    timestamp_to: params.timeTo?.toISOString(),
    unique: params.unique,
  };
}

export const getLorawanMessages = (params: LoRaWANRawMessagesQuery): Promise<ApiResponse<LorawanMessage[]>> => {
  const body = {
    queryStringParameters: formatQuery(normalizeParams(params)),
  };

  return API
    .get(config.name, urls.messages, body)
    .catch(errorHandler);
};

export const getLorawanMessagesTotal = (params: LoRaWANRawMessagesQuery): Promise<{ total: number }> => {
  const body = {
    queryStringParameters: formatQuery(normalizeParams(params)),
  };

  return API
    .get(config.name, urls.total, body)
    .catch(errorHandler);
};