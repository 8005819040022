import moment from 'moment';

export interface RawMessages {
  device_id: string,
  bs_id:	number, // integer($int64), example: 5088
  zone_id:	number, // integer($int64), example: 1
  demodulation_time: string, // string($date-time)
  sent_time: string, // string($date-time)
  iterator: number, // integer, maximum: 15, example: 5
  payload: string, // example: DEADBEEF
  signal_strength: number, // integer, example: -1
  actuality_type: number, // string, example: actual
  data_link_protocol_id: number // integer, example: 1
  trace_id: string, // string($uuid), example: 12345678-1234-1234-1234-123456789012
  group_name: string, // string, example: Odeon5
  group_id: number,
  is_unique: boolean
}

export interface RawMessagesWithNewFlag extends RawMessages {
  new?: boolean
}

export enum EnumRawMessagesFields {
  owner = 'owner',
  projects = 'projects',
  zones = 'zones',
  groups = 'groups',
  devices = 'devices',
  networkId = 'networkId',
  bsid = 'bsid',
  traceid = 'traceid',
  actuality = 'actuality',
  protocol = 'protocol',
  timeFrom = 'timeFrom',
  timeTo = 'timeTo',
  unique = 'unique',
  limit = 'limit',
  offset = 'offset',
}

export interface RawMessagesFilters {
  [EnumRawMessagesFields.owner]?: number;
  [EnumRawMessagesFields.projects]?: number[];
  [EnumRawMessagesFields.zones]?: number[];
  [EnumRawMessagesFields.groups]?: number[];
  [EnumRawMessagesFields.devices]: string[];
  [EnumRawMessagesFields.networkId]: string[];
  [EnumRawMessagesFields.bsid]?: number | string;
  [EnumRawMessagesFields.traceid]?: string;
  [EnumRawMessagesFields.unique]?: boolean;
  [EnumRawMessagesFields.timeFrom]?: moment.Moment | null;
  [EnumRawMessagesFields.timeTo]?: moment.Moment | null;
  [EnumRawMessagesFields.actuality]?: string;
  [EnumRawMessagesFields.protocol]?: string;
}

export interface RawMessagesFiltersQuery extends RawMessagesFilters {
  limit: number
  offset: number
}

export const initRawMessagesFilters: RawMessagesFilters = {
  [EnumRawMessagesFields.owner]: undefined,
  [EnumRawMessagesFields.projects]: [],
  [EnumRawMessagesFields.zones]: [],
  [EnumRawMessagesFields.groups]: [],
  [EnumRawMessagesFields.devices]: [],
  [EnumRawMessagesFields.networkId]: [],
  [EnumRawMessagesFields.bsid]: undefined,
  [EnumRawMessagesFields.traceid]: undefined,
  [EnumRawMessagesFields.unique]: undefined,
  [EnumRawMessagesFields.timeFrom]: moment().subtract(1, 'days'),
  [EnumRawMessagesFields.timeTo]: null,
  [EnumRawMessagesFields.actuality]: undefined,
  [EnumRawMessagesFields.protocol]: undefined,
};

export interface RawOnline {
  demodulation_date: string;
  total: number;
};

