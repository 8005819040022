import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { GetMessageParam } from 'models/message-history';
import { getMessage } from 'clients/message-history';
import { MESSAGE_KEY } from './query';


export function useMessageHistoryQuery(param: GetMessageParam, enabled = true) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: [`${ MESSAGE_KEY }/get`, param],
    queryFn: async () => {
      const res = await getMessage(param);
      return getResponseData(res);
    },
    enabled: enabled,
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching message history: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    message: queryResult.data,
  };
}