import React, { useEffect } from 'react';
import { FormProps } from 'form';
import { useDeepCompareEffect } from 'react-use';
import { useVirtualDevicesParamsSelector } from 'hooks/device-management';
import { DeviceType } from 'models/device-emulation';
import { transformToHex, transformToNumber } from 'helpers';
import { validate } from './validator';
import { CCMessageTypeOptions, FormField, FormState, MessageType, PSMessageTypeOptions } from './types';
// components
import { SingleSelectControl, TextControl } from 'components/Controls';

type Props =
  FormProps<FormState, FormField>
  & {
    deviceType: DeviceType,
  }

export const SendMessageForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate, deviceType } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? Object.values(FormField);
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  useEffect(() => {
    if (deviceType === DeviceType.CAR_COUNTER) {
      if (state[FormField.messageType] !== MessageType.REGULAR_CAR_COUNTER) {
        handleChange(FormField.messageType, MessageType.REGULAR_CAR_COUNTER);
      }
    } else {
      if (state[FormField.messageType] === MessageType.REGULAR_CAR_COUNTER) {
        handleChange(FormField.messageType, MessageType.OCCUPANCY_CHANGE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType]);
  const { isLoading: areDevicesLoading, devices } = useVirtualDevicesParamsSelector({});

  return (
    <form noValidate autoComplete="off">
      { showFields.includes(FormField.device) && (
        <SingleSelectControl
          isRequired
          isDisabled={ isView || disabledFields.includes(FormField.device) }
          label="Device ID"
          name={ FormField.device }
          isLoading={ areDevicesLoading }
          values={ devices.map(device => ({
            label: device.device_id,
            value: device.device_id,
            isDisabled: !device.position_id,
            helperText: device.position_id ? undefined : 'Not positioned',
          })) }
          selected={ state[FormField.device] }
          changeHandler={ deviceId => handleChange(FormField.device, deviceId) }
          error={ showErrors.includes(FormField.device) ? errors[FormField.device] : undefined }
        />
      ) }
      { showFields.includes(FormField.messageType) && (
        <SingleSelectControl
          isRequired
          isDisabled={ isView || disabledFields.includes(FormField.messageType) }
          label="Message type"
          name={ FormField.messageType }
          values={ deviceType === DeviceType.CAR_COUNTER ? CCMessageTypeOptions : PSMessageTypeOptions }
          selected={ state[FormField.messageType] }
          changeHandler={ messageType => handleChange(FormField.messageType, messageType) }
          error={ showErrors.includes(FormField.messageType) ? errors[FormField.messageType] : undefined }
        />
      ) }
      { showFields.includes(FormField.occupancy) && (
        <TextControl
          disabled={ isView || disabledFields.includes(FormField.occupancy) }
          label="Next occupancy status"
          name={ FormField.occupancy }
          value={ state[FormField.occupancy] ?? '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.occupancy, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.occupancy) ? errors[FormField.occupancy] !== undefined : false }
          helperText={ showErrors.includes(FormField.occupancy) ? errors[FormField.occupancy] : undefined }
        />
      ) }
      { showFields.includes(FormField.tag) && (
        <TextControl
          required
          disabled={ isView || disabledFields.includes(FormField.tag) }
          label="SDI Tag ID"
          name={ FormField.tag }
          value={ state[FormField.tag] ?? '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.tag, transformToHex(String(event.target.value)).slice(0, 8));
          } }
          error={ showErrors.includes(FormField.tag) ? errors[FormField.tag] !== undefined : false }
          helperText={ showErrors.includes(FormField.tag) ? errors[FormField.tag] : undefined }
        />
      ) }
      { showFields.includes(FormField.counter) && (
        <TextControl
          required
          disabled={ isView || disabledFields.includes(FormField.counter) }
          label="Counter"
          name={ FormField.counter }
          value={ String(state[FormField.counter] ?? 0) }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.counter, Number(transformToNumber(event.target.value)));
          } }
          error={ showErrors.includes(FormField.counter) ? errors[FormField.counter] !== undefined : false }
          helperText={ showErrors.includes(FormField.counter) ? errors[FormField.counter] : undefined }
        />
      ) }
    </form>
  );
};
