import { RawMessages } from 'models';
import { useMessageHistoryQuery } from 'hooks/message-history';

// components
import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { JsonPreview } from 'components';

interface Props {
  cols: number;
  message: RawMessages;
}

export const MessageDetailInfo = ({ cols, message }: Props) => {
  const msgHistory = useMessageHistoryQuery({ trace_id: message.trace_id }, message.is_unique);

  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Grid container spacing={ 0 }>
          <Grid item xs={ 12 } md={ 6 }>
            <Typography variant="subtitle2">
              Actuality Type: <Typography variant="caption">{ message.actuality_type || 'None' }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Data link protocol id: <Typography variant="caption">{ message.data_link_protocol_id || 'None' }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Trace ID: <Typography variant="caption">{ message.trace_id || 'None' }</Typography>
            </Typography>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            { message.is_unique
              ? <JsonPreview title="Decoded" isLoading={ msgHistory.isLoading } value={ msgHistory.message }/>
              : <Typography variant="subtitle2">Expand unique message row to see decoded message</Typography>
            }
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};