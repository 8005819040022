import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useFormActionLoader } from 'hooks/form';
import { dmPositionGroupPath } from 'routing/paths';
import { GroupCreateFields, GroupType, Group } from 'models/device-management';
import { groupTypes } from 'models/device-management/dm';
import { DoCreateGroup } from 'actions/device-management/groups';
import { useForm, Controller } from 'react-hook-form-v6';

// components
import { ZoneSelectControl, LevelSelect  } from 'components/Controls';
import { SelectControl } from 'components/Controls/Select';
import { SuccessButton } from 'components/Buttons';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';

// styles
import { useStyles } from 'styles/forms';

type FormValues = Omit<GroupCreateFields, 'zone_id'> & { zone_id?: number }

export const CreateGroup = (): JSX.Element => {
  const classes = useStyles();
  const { state } = useLocation<{ zoneId: FormValues['zone_id'] } | undefined>();
  const zoneId = state?.zoneId;
  const { doAction } = useFormActionLoader();
  const defaultValues: FormValues = {
    name: '',
    type: undefined,
    custom_id: undefined,
    zone_id: zoneId || undefined,
  };

  const { control, getValues, setValue,  formState: { errors, isDirty }, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleSubmit = (values: FormValues) =>  {
    const valuesWithType = {
      ...values,
      // `values.zone_id as number` is valid as long as there's `is.required` validator
      zone_id: values.zone_id as number,
      type: values.type || GroupType.MarkedParkingBay,
    };

    doAction({
      action: DoCreateGroup([valuesWithType]),
      onSuccess: (group: Group) => ({ to: dmPositionGroupPath(group.id) })
    });
  };

  return (
    <div>
      <Helmet>
        <title>Create group</title>
      </Helmet>
      <Paper className={classes.container}>
        <Grid item={ true } xs={ 12 } sm={ 12 } className={ classes.gridEnd }>
          <SuccessButton
            label="Save"
            // pending={ isSubmitting }
            disabled={ !isDirty }
            onClick={() => {
              const v = getValues();
              handleSubmit(v);
            }}
          />
        </Grid>
        <FormGroup className={ classes.fields }>
          <Controller
            render={props => <TextField
              {...props}
              label="Group Name"
              error={!!errors.name}
              helperText={errors.name && 'Required'}
            />}
            name="name"
            control={control}
            rules={{ required: true }}
          />
          <br />
          <Controller
            render={props => (
              <SelectControl
                {...props}
                label="Group Type"
                selected={ props.value }
                options={ Object.entries(groupTypes).map(el => ({
                  value: el[0],
                  label: el[1]
                })) }
              />
            )}
            name="type"
            control={control}
            rules={{ required: true }}
          />
          <br />
          <Controller
            render={props => <TextField
              {...props}
              label="Custom ID"
            />}
            name="custom_id"
            control={control}
          />
          <Controller
            isDisabled={ !!zoneId }
            as={props => (<ZoneSelectControl
              {...props}
              selected={props.value}
              onChange={e => {
                props.onChange(e);
                setValue('level_id', null);
              }}
            />)}
            name={ 'zone_id' }
            control={control}
          />
          <Controller
            isDisabled={ !watch('zone_id') }
            as={props => (<LevelSelect
              {...props}
              selected={ props.value }
              zoneId={ watch('zone_id') }
            />)}
            name={ 'level_id' }
            control={control}
          />
        </FormGroup>
      </Paper>
    </div>
  );
};
